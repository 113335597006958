//
// Range slider
//

.irs {
  font-family: $font-family-base;
}

.irs--round {
  .irs-bar,
  .irs-to,
  .irs-from,
  .irs-single {
    background: $primary !important;
    font-size: 11px;
  }

  .irs-to,
  .irs-from,
  .irs-single {
    &:before {
      display: none;
    }
  }

  .irs-line {
    background: $gray-300;
    border-color: $gray-300;
  }

  .irs-grid-text {
    font-size: 11px;
    color: $gray-500;
  }

  .irs-min,
  .irs-max {
    color: $gray-500;
    background: $gray-300;
    font-size: 11px;
  }

  .irs-handle {
    border: 2px solid $primary;
    width: 16px;
    height: 16px;
    top: 29px;
    background-color: $card-bg !important;
  }
}
